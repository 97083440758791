<template>
    <div class="waitData">
        <el-form label-width="80px" label-position="left" size="mini">
            <el-form-item label="公司名称">
                <div class="info">{{ waitData.companyName || '--' }}</div>
            </el-form-item>
            <el-form-item label="客户类型">
                <div class="info">
                    {{
                        waitData.enterpriseType == 1
                            ? '企业'
                            : waitData.enterpriseType == 2
                            ? '个人'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <template v-for="item in waitData.libraryDomainBOList">
                <div :key="item.id">
                    <el-form-item label="域名">
                        <div class="info">{{ item.domain }}</div>
                    </el-form-item>
                    <div class="domain_detail" v-show="item.tag">
                        <div class="domain_info">
                            <el-form-item label="原邮箱品牌">
                                <div
                                    class="info"
                                    v-if="item.enterpriseDomainDO"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        v-if="
                                            $tableDataHandle.dominBrand(
                                                item.enterpriseDomainDO.domainMX
                                            ) == '其他邮箱'
                                        "
                                        :content="
                                            item.enterpriseDomainDO.domainMX
                                        "
                                        placement="top"
                                    >
                                        <span>
                                            {{
                                                $tableDataHandle.dominBrand(
                                                    item.enterpriseDomainDO
                                                        .domainMX
                                                )
                                            }}
                                        </span>
                                    </el-tooltip>
                                    <span v-else>
                                        {{
                                            $tableDataHandle.dominBrand(
                                                item.enterpriseDomainDO.domainMX
                                            )
                                        }}
                                    </span>
                                </div>
                                <div v-else>- -</div>
                            </el-form-item>
                            <el-form-item label="备案人">
                                <div
                                    class="info"
                                    v-if="item.enterpriseDomainDO"
                                >
                                    {{
                                        item.enterpriseDomainDO.icpOwner || '--'
                                    }}
                                </div>
                                <div v-else>- -</div>
                            </el-form-item>
                            <el-form-item label="网站名">
                                <div
                                    class="info"
                                    v-if="item.enterpriseDomainDO"
                                >
                                    {{
                                        item.enterpriseDomainDO.icpRecordName ||
                                        '--'
                                    }}
                                </div>
                                <div v-else>- -</div>
                            </el-form-item>
                            <el-form-item label="whois">
                                <div
                                    class="info"
                                    v-if="item.enterpriseDomainDO"
                                >
                                    {{
                                        item.enterpriseDomainDO
                                            .icpDomainRecordTime || '--'
                                    }}
                                    <i class="el-icon-right"></i>
                                    {{
                                        item.enterpriseDomainDO.whoisDueDate
                                            ? item.enterpriseDomainDO.whoisDueDate.split(
                                                  ' '
                                              )[0]
                                            : '--'
                                    }}
                                </div>
                                <div v-else>- -</div>
                            </el-form-item>
                            <el-form-item style="margin-bottom: 0">
                                <span
                                    class="mini_btn"
                                    @click="hideDetail(item.id)"
                                    >收起 <i class="el-icon-arrow-up"></i>
                                </span>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item style="padding-left: 10px" v-show="!item.tag">
                        <span class="mini_btn" @click="showDetail(item.id)"
                            >详细信息 <i class="el-icon-arrow-down"></i>
                        </span>
                    </el-form-item>
                </div>
            </template>
            <el-form-item label="联系方式">
                <div
                    v-if="
                        waitData.libraryContactBOList &&
                        waitData.libraryContactBOList.length > 0
                    "
                >
                    <div
                        class="info contact_way_box"
                        v-for="item in waitData.libraryContactBOList"
                        :key="item.id"
                    >
                        <span class="contact_way">{{
                            $tableDataHandle.contactWay(item)
                        }}</span>
                        <span class="contact_way">{{ item.contactName }}</span>
                        <p class="contact_way">{{ item.contactContent }}</p>
                    </div>
                </div>
                <div class="info contact_way_box" v-else>
                    <span class="contact_way">- -</span>
                    <span class="contact_way">- -</span>
                    <p class="contact_way">- -</p>
                </div>
            </el-form-item>
            <el-form-item label="备注">
                <div class="info">{{ waitData.remark || '--' }}</div>
            </el-form-item>
            <div>
                <el-form-item label="用户数">
                    <div class="info">{{ waitData.userNo || '--' }}</div>
                </el-form-item>
                <el-form-item label="QQ号">
                    <div class="info">{{ waitData.qq || '--' }}</div>
                </el-form-item>
                <el-form-item label="微信号">
                    <div class="info">{{ waitData.weChat || '--' }}</div>
                </el-form-item>
                <el-form-item label="联系邮箱">
                    <div class="info">{{ waitData.email || '--' }}</div>
                </el-form-item>
                <el-form-item label="域名备注">
                    <div class="info">{{ waitData.companyDomain || '--' }}</div>
                </el-form-item>
                <el-form-item label="联系方式备注">
                    <div class="info">{{ waitData.contactWay || '--' }}</div>
                </el-form-item>
            </div>
            <!-- <el-form-item>
                <span class="hide_btn" @click="changeFormStatus"
                    >{{ tag ? '收起' : '展开' }}
                    <i
                        :class="tag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    ></i
                ></span>
            </el-form-item> -->
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['formData'],
    data() {
        return {
            waitData: {},
        };
    },
    watch: {
        formData: {
            deep: true,
            handler(val, oldVal) {
                this.waitData = val;
                this.waitData.libraryDomainBOList.forEach((item) => {
                    item.tag = false;
                });
            },
        },
    },
    components: {},

    mounted() {
        this.waitData = this.formData;
    },
    methods: {
        // 收起详细信息
        hideDetail(id) {
            let index = this.waitData.libraryDomainBOList.findIndex(
                (item) => item.id == id
            );
            this.waitData.libraryDomainBOList[index].tag = false;
            let data = this.waitData;
            this.waitData = [];
            this.waitData = data;
        },
        // 显示详细信息
        showDetail(id) {
            let index = this.waitData.libraryDomainBOList.findIndex(
                (item) => item.id == id
            );
            this.waitData.libraryDomainBOList[index].tag = true;
            let data = this.waitData;
            this.waitData = [];
            this.waitData = data;
            //  this.formData = [];
        },
        // changeFormStatus() {
        //     this.tag = !this.tag;
        // },
    },
};
</script>

<style lang="scss" scoped>
.waitData {
    background-color: #fff;

    padding: 20px 10px 0 30px;
}
.waitData .info {
    padding-left: 10px;
    width: 250px;
    color: #333;
    // font-weight: 600;
    box-sizing: border-box;
    height: 100%;
}
.waitData .domain_detail {
    margin-bottom: 10px;
}
.waitData .domain_info {
    background: rgba(255, 168, 2, 0.05);
    border-radius: 8px;
    border: 1px solid #ffca66;
    padding: 10px;
}
.waitData .domain_info .info {
    background: #fdf7ec;
    border-radius: 2px;
    border: 1px solid #f0f0f0;
}
.waitData .mini_btn {
    color: #ffa802;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
}
.waitData .hide_btn {
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    display: inline-block;
    color: #2370eb;
    cursor: pointer;
}
.waitData .contact_way {
    padding: 0 5px;
    box-sizing: border-box;
    white-space: nowrap;
    // display: flex;
    // align-items: center;
    /* text-overflow: ellipsis;
  overflow: hidden; */
}
.waitData .contact_way_box {
    background-color: #fff;
    // display: flex;
    // flex-wrap: nowrap;
    margin-bottom: 8px;
    padding-left: 0;
}
.waitData .contact_way:nth-child(2) {
    flex: 1;
    margin: 0 5px;
    word-break: break-all;
    white-space: normal;
}
/deep/ .el-form-item__label {
    color: #666 !important;
    font-weight: normal !important;
}
/deep/ .el-form-item {
    margin: 8px !important;
}
</style>
