<template>
    <div class="add_cutomer_box">
        <div
            class="title_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <span><i>+</i> 添加为客户</span>
            <el-button @click="onClose">
                <i class="el-icon-arrow-left"></i> 返回列表
            </el-button>
        </div>
        <div class="add_customer">
            <div class="left_form">
                <p class="wait_data"><span>待添加资料</span></p>
                <WaitData :formData="formData"></WaitData>
                <p class="business_info">
                    <span>工商信息</span>
                </p>
                <BusinessInfo
                    :businessInfo="formData.enterpriseNameDO"
                    v-show="tag"
                ></BusinessInfo>
            </div>
            <div class="right_form">
                <div class="customer_form">
                    <span>添加客户</span>
                    <!-- <div>
                        <span
                            @click="changeComp(true)"
                            :class="active ? 'active' : ''"
                            >基础信息</span
                        >
                        <span
                            @click="changeComp(false)"
                            :class="!active ? 'active' : ''"
                            >联系人</span
                        >
                    </div> -->
                </div>
                <div class="right_form_main">
                    <CustomerForm
                        @inputCompanyName="inputCompanyName"
                        ref="customerForm"
                    ></CustomerForm>
                    <ContactsForm ref="contactsForm"></ContactsForm>
                </div>
            </div>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取消</el-button>
            <el-button
                type="primary"
                size="mini"
                v-if="showBtn"
                round
                @click="onSubmit(1)"
                >保存</el-button
            >
            <el-button
                type="primary"
                size="mini"
                v-if="showBtn"
                round
                @click="onSubmit(2)"
                >保存并新建线索</el-button
            >
        </div>
        <!-- 保存并新建线索 -->
        <el-drawer
            :visible.sync="drawerAddClue"
            :direction="direction"
            :before-close="handleClose"
            size="560px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户新增线索
                </div>
            </template>
            <AddClue
                @close="handleClose"
                @onSubmit="onSubmitClue"
                ref="addClue"
            />
        </el-drawer>
    </div>
</template>

<script>
import {
    libraryGet,
    dealingList,
    relationCustomer,
    customerAddDomain,
} from '@/api/database/database';
import { addCustomer, contactAdd } from '@/api/user/ae/customer.js';
import { clueAdd } from '@/api/clue/meClue.js';
import WaitData from './components/waitData.vue';
import BusinessInfo from './components/businessInfo.vue';
import CustomerForm from './components/customerFrom.vue';
import ContactsForm from './components/contactsForm.vue';
import AddClue from '../addClue/addClue.vue';
import {
    dealingAdd,
    customerMainContactAdd,
    nameMustGet,
} from '@/api/customer/customer.js';
import {
    getCustomerId,
    getCustomerAdmin,
    customerAddMy,
} from '@/api/user/ae/customer.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            formData: {},
            tag: true,
            active: true,
            showBtn: true,
            customerFormTag: true,
            customerDO: {},
            customerContactList: [],
            num: 0,
            customer: {},
            drawerAddClue: false,
            direction: 'rtl',
            tableRowId: 0,
        };
    },
    components: {
        WaitData,
        BusinessInfo,
        CustomerForm,
        ContactsForm,
        AddClue,
    },
    created() {},
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            sessionStorage.setItem('customerlist', 1);
            this.$refs.contactsForm.$data.customerContactList = [];
            this.$refs.customerForm.$data.customerTagList = [];
            this.$refs.customerForm.$data.customerDomainList = [];
            this.$refs.customerForm.$data.address = [];
            this.$refs.customerForm.$data.location = '';
            this.$refs.customerForm.$data.customerDO = {
                companyName: '',
                companyDomain: '',
                customerMainContactList: [],
                number: '',
                enterpriseType: 1,
                remark: '',
                primaryEmailBrand: '',
                contactRemark: '',
            };
            // this.$refs.contactsForm.$data.
            this.libraryGet();
        },

        inputCompanyName(val) {
            let data = {
                param: {
                    companyName: val,
                    enterpriseType: 1,
                },
            };
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        this.customerFormTag = false;
                        this.$refs.customerForm.$data.tag = false;

                        this.$refs.customerForm.$data.companyList =
                            res.data.list;
                        if (this.formData.libraryDomainBOList.length > 0) {
                            this.$refs.customerForm.$data.customerDomainList =
                                [];
                            this.formData.libraryDomainBOList.forEach(
                                (item, index) => {
                                    this.$refs.customerForm.$data.customerDomainList.push(
                                        {
                                            domain: item.domain,
                                        }
                                    );
                                }
                            );
                        } else {
                            this.$refs.customerForm.$data.customerDomainList = [
                                { domain: '' },
                            ];
                        }
                    } else {
                        this.customerFormTag = true;
                        this.$refs.customerForm.$data.tag = true;
                    }
                }
            });
        },
        // 修改工商信息展示状态
        changeStatus() {
            this.tag = !this.tag;
        },
        // 变更基础信息和联系人
        changeComp(bool) {
            this.active = bool;
        },
        libraryGet() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            libraryGet(data).then((res) => {
                if (res.code == 200) {
                    this.formData = res.data;
                    // 客户

                    this.$refs.customerForm.$data.formDataId = res.data.id;
                    this.$refs.customerForm.$data.customerDO.companyName =
                        res.data.companyName;
                    this.inputCompanyName(res.data.companyName);
                    this.$refs.customerForm.$data.customerDO.companyDomain =
                        res.data.libraryDomainBOList[0].domain;
                    this.$refs.customerForm.$data.customerDO.contactRemark =
                        res.data.contactWay;
                    this.formData.libraryIntercourseBOList =
                        res.data.libraryIntercourseBOList;
                    if (res.data.libraryDomainBOList.length > 1) {
                        this.$refs.customerForm.$data.customerDomainList = [];
                        res.data.libraryDomainBOList.forEach((item, index) => {
                            if (index > 0) {
                                this.$refs.customerForm.$data.customerDomainList.push(
                                    {
                                        domain: item.domain,
                                    }
                                );
                            }
                        });
                    } else {
                        this.$refs.customerForm.$data.customerDomainList = [
                            { domain: '' },
                        ];
                    }
                    if (res.data.libraryContactBOList.length == 0) {
                        this.$refs.customerForm.$data.customerDO.customerMainContactList =
                            [
                                {
                                    contactWay: 1,
                                    contactContent: '',
                                },
                            ];
                    } else {
                        res.data.libraryContactBOList.forEach((item) => {
                            this.$refs.customerForm.$data.customerDO.customerMainContactList.push(
                                {
                                    contactWay: item.contactWay,
                                    contactContent: item.contactContent,
                                    contactName: item.contactName,
                                }
                            );
                        });
                    }
                    this.$refs.customerForm.$data.customerDO.number =
                        res.data.userNo;
                    this.$refs.customerForm.$data.customerDO.remark =
                        res.data.remark;
                    // 联系人
                    if (res.data.libraryContactBOList.length > 0) {
                        res.data.libraryContactBOList.forEach((item) => {
                            this.$refs.contactsForm.$data.customerContactList.push(
                                {
                                    companyName: res.data.companyName,
                                    contactName: item.contactName,
                                    sex: 1,
                                    customerContactWayDOList: [
                                        {
                                            contactWay: item.contactWay,
                                            contactContent: item.contactContent,
                                        },
                                    ],
                                }
                            );
                        });
                        for (
                            let i = 0;
                            i <
                            this.$refs.contactsForm.$data.customerContactList
                                .length -
                                1;
                            i++
                        ) {
                            for (
                                let j = i + 1;
                                j <
                                this.$refs.contactsForm.$data
                                    .customerContactList.length -
                                    1;
                                j++
                            ) {
                                if (
                                    this.$refs.contactsForm.$data
                                        .customerContactList[i].contactName ==
                                    this.$refs.contactsForm.$data
                                        .customerContactList[j].contactName
                                ) {
                                    this.$refs.contactsForm.$data.customerContactList[
                                        i
                                    ].customerContactWayDOList.push(
                                        this.$refs.contactsForm.$data
                                            .customerContactList[j]
                                            .customerContactWayDOList[0]
                                    );
                                    this.$refs.contactsForm.$data.customerContactList.splice(
                                        j,
                                        1
                                    );
                                }
                            }
                        }
                    } else {
                        this.$refs.contactsForm.$data.customerContactList = [
                            {
                                companyName: data.companyName,
                                contactName: '',
                                sex: 1,
                                customerContactWayDOList: [
                                    {
                                        contactWay: 1,
                                        contactContent: '',
                                    },
                                ],
                            },
                        ];
                    }
                    this.$refs.customerForm.$data.customerContactList =
                        this.$refs.contactsForm.$data.customerContactList;
                    // console.log(
                    //     this.$refs.contactsForm.$data.customerContactList
                    // );
                }
            });
        },
        // 保存客户
        async onSubmit(num) {
            // this.$emit("addClue", 123);
            // return;
            this.customerDO = this.$refs.customerForm.$data.customerDO;
            if (this.customerDO.enterpriseType == 2) {
                this.customerDO.provinceId =
                    Number(this.$refs.customerForm.$data.address[0]) || 0;
                this.customerDO.cityId =
                    Number(this.$refs.customerForm.$data.address[1]) || 0;
            }

            this.customerContactList =
                this.$refs.contactsForm.$data.customerContactList;
            this.customerContactList.forEach((item) => {
                this.$choiceLabel.state.MaillabelList.forEach((itm) => {
                    item.contactTagList = itm;
                });
            });
            if (this.customerDO.companyName == '') {
                return this.$message.error('请输入公司名称');
            }
            for (let i in this.customerContactList) {
                if (!this.customerContactList[i].contactName) {
                    return this.$message.error('请输入联系人');
                }
                for (let j in this.customerContactList[i]
                    .customerContactWayDOList) {
                    if (
                        !this.customerContactList[i].customerContactWayDOList[j]
                            .contactWay ||
                        !this.customerContactList[i].customerContactWayDOList[j]
                            .contactContent
                    ) {
                        return this.$message.error('请输入完整联系人联系方式');
                    }
                }
            }
            if (this.customerDO.enterpriseType == 1) {
                let obj = {
                    param: {
                        companyName: this.customerDO.companyName,
                    },
                };
                let res = await nameMustGet(obj);
                if (res.code == 200) {
                    if (!res.data || res.data.aiChaStatus != 1) {
                        return this.$message.error(
                            '未查询到工商局注册信息，请检查公司名称'
                        );
                    }
                    this.customerDO.cityId = res.data.cityCode;
                    this.customerDO.provinceId = res.data.provinceCode;
                    this.customerDO.address = res.data.companyAddress;
                }
            }
            if (
                !this.$refs.customerForm.$data.tag &&
                this.customerDO.enterpriseType == 1
            ) {
                let tableRadio = this.$refs.customerForm.$data.tableRadio;
                let customerDomainList =
                    this.$refs.customerForm.$data.customerDomainList;
                let customerMainContactList =
                    this.customerDO.customerMainContactList;
                console.log(this.customerDO);
                let libraryIntercourseBOList =
                    this.formData.libraryIntercourseBOList;
                if (!tableRadio.id) {
                    return this.$message.error('请选择客户');
                }

                // return;
                let data = {
                    param: {
                        customerDO: { id: tableRadio.id },
                        libraryDO: { id: this.$route.query.id },
                    },
                };
                relationCustomer(data).then((res) => {
                    if (res.code == 200) {
                        let data2 = {
                            param: {
                                companyName: tableRadio.companyName,
                                adminId: sessionStorage.getItem('adminId'),
                            },
                        };
                        getCustomerAdmin(data2).then((res) => {
                            if (res.code == 200) {
                                if (res.data.total > 0) {
                                    // 添加联系方式
                                    this.customerContactList.forEach((item) => {
                                        let data3 = {
                                            param: {
                                                customerContactDO: item,
                                            },
                                        };

                                        data3.param.customerContactDO.customerId =
                                            res.data.list[0].id;
                                        data3.param.customerContactDO.followAdminId =
                                            sessionStorage.getItem('adminId');
                                        contactAdd(data3).then((res) => {
                                            if (res.code != 200) {
                                                return;
                                            }
                                        });
                                    });
                                    // 添加域名
                                    customerDomainList.length > 0
                                        ? customerDomainList.forEach((item) => {
                                              if (!item.tag) {
                                                  let data4 = {
                                                      param: {
                                                          customerId:
                                                              res.data.list[0]
                                                                  .id,
                                                          domain: item.domain,
                                                      },
                                                  };
                                                  customerAddDomain(data4).then(
                                                      (res) => {}
                                                  );
                                              }
                                          })
                                        : '';
                                    // 添加客户联系方式
                                    customerMainContactList.length > 0
                                        ? customerMainContactList.forEach(
                                              (item) => {
                                                  if (!item.tag) {
                                                      let data5 = {
                                                          param: {
                                                              customerId:
                                                                  res.data
                                                                      .list[0]
                                                                      .id,
                                                              contactWay:
                                                                  item.contactWay,
                                                              contactName:
                                                                  item.contactName,
                                                              contactContent:
                                                                  item.contactContent,
                                                          },
                                                      };
                                                      customerMainContactAdd(
                                                          data5
                                                      ).then((res) => {});
                                                  }
                                              }
                                          )
                                        : '';
                                    libraryIntercourseBOList.length > 0
                                        ? // 添加往来
                                          libraryIntercourseBOList.forEach(
                                              (item) => {
                                                  let data5 = {
                                                      param: {
                                                          contactWay:
                                                              item.contactWay,
                                                          customerId:
                                                              res.data.list[0]
                                                                  .id,
                                                          followAdminId:
                                                              item.adminId,
                                                          intercourseContent:
                                                              item.intercourseContent,
                                                          intercourseTime:
                                                              item.intercourseTime,
                                                          intercourseTitle:
                                                              item.intercourseTitle,
                                                          remark: item.remark,
                                                          describeList:
                                                              item.describeList,
                                                      },
                                                  };
                                                  dealingAdd(data5).then(
                                                      (res) => {}
                                                  );
                                              }
                                          )
                                        : '';

                                    if (num == 1) {
                                        this.$message.success('操作成功');
                                        this.onClose();
                                    } else {
                                        this.active = true;
                                        // 保存并新建线索
                                        this.addClueDrawer(res.data.list[0]);
                                    }
                                } else {
                                    let datas = {
                                        param: {
                                            id: tableRadio.id,
                                        },
                                    };
                                    customerAddMy(datas).then((ress) => {
                                        if (ress.code == 200) {
                                            this.customerContactList.forEach(
                                                (item) => {
                                                    let data3 = {
                                                        param: {
                                                            customerContactDO:
                                                                item,
                                                        },
                                                    };

                                                    data3.param.customerContactDO.customerId =
                                                        tableRadio.id;
                                                    data3.param.customerContactDO.followAdminId =
                                                        sessionStorage.getItem(
                                                            'adminId'
                                                        );
                                                    contactAdd(data3).then(
                                                        (res) => {
                                                            if (
                                                                res.code != 200
                                                            ) {
                                                                return;
                                                            }
                                                        }
                                                    );
                                                }
                                            );
                                            customerDomainList.length > 0
                                                ? customerDomainList.forEach(
                                                      (item) => {
                                                          if (!item.tag) {
                                                              let data4 = {
                                                                  param: {
                                                                      customerId:
                                                                          tableRadio.id,
                                                                      domain: item.domain,
                                                                  },
                                                              };
                                                              customerAddDomain(
                                                                  data4
                                                              ).then(
                                                                  (res) => {}
                                                              );
                                                          }
                                                      }
                                                  )
                                                : '';
                                            // 添加客户联系方式
                                            customerMainContactList.length > 0
                                                ? customerMainContactList.forEach(
                                                      (item) => {
                                                          if (!item.tag) {
                                                              let data5 = {
                                                                  param: {
                                                                      customerId:
                                                                          tableRadio.id,
                                                                      contactWay:
                                                                          item.contactWay,
                                                                      contactName:
                                                                          item.contactName,
                                                                      contactContent:
                                                                          item.contactContent,
                                                                  },
                                                              };
                                                              customerMainContactAdd(
                                                                  data5
                                                              ).then(
                                                                  (res) => {}
                                                              );
                                                          }
                                                      }
                                                  )
                                                : '';
                                            libraryIntercourseBOList.length > 0
                                                ? libraryIntercourseBOList.forEach(
                                                      (item) => {
                                                          let data5 = {
                                                              param: {
                                                                  contactWay:
                                                                      item.contactWay,
                                                                  customerId:
                                                                      tableRadio.id,
                                                                  followAdminId:
                                                                      item.adminId,
                                                                  intercourseContent:
                                                                      item.intercourseContent,
                                                                  intercourseTime:
                                                                      item.intercourseTime,
                                                                  intercourseTitle:
                                                                      item.intercourseTitle,
                                                                  remark: item.remark,
                                                                  describeList:
                                                                      item.describeList,
                                                              },
                                                          };
                                                          dealingAdd(
                                                              data5
                                                          ).then((res) => {});
                                                      }
                                                  )
                                                : '';
                                            if (num == 1) {
                                                this.$message.success(
                                                    '操作成功'
                                                );
                                                this.onClose();
                                            } else {
                                                this.active = true;
                                                // 保存并新建线索
                                                this.addClueDrawer(tableRadio);
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });

                return;
            }
            let data = {
                param: {
                    customerDO: this.customerDO,
                    customerTagList: this.$choiceLabel.state.labelList,
                    customerDomainList:
                        this.$refs.customerForm.$data.customerDomainList,
                    libraryDO: {
                        id: this.formData.id,
                    },
                    customerIntercourseList:
                        this.formData.libraryIntercourseBOList,
                    customerContactList: this.customerContactList,
                },
            };
            for (let i in this.customerDO.customerMainContactList) {
                if (this.customerDO.customerMainContactList[i].contactContent) {
                    data.param.customerDO = {
                        ...this.customerDO,
                        customerMainContactList:
                            this.customerDO.customerMainContactList,
                    };
                } else {
                    data.param.customerDO = {
                        ...this.customerDO,
                        customerMainContactList: [],
                    };
                }
            }
            if (this.customerDO.enterpriseType == 3) {
                data.param.customerDO.location =
                    this.$refs.customerForm.location;
            }
            // // console.log(data);
            addCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.showBtn = false;
                    if (num == 1) {
                        this.$message.success('操作成功');
                        this.onClose();
                    } else {
                        this.active = true;

                        // 保存并新建线索
                        this.addClueDrawer(res.data);
                    }
                }
            });
        },
        // 保存并新建线索弹窗
        addClueDrawer(row) {
            this.tableRowId = row.id;
            let data = {
                param: {
                    id: row.id,
                },
            };
            getCustomerId(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.drawerAddClue = true;
                    setTimeout(() => {
                        this.$refs.addClue.getData(res.data);
                        // this.$refs.addClue.customerDealings(row.id);
                    }, 0);
                }
            });
        },

        // 生成线索
        onSubmitClue(data) {
            clueAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.$router.go(-1);
                }
            });
        },
        handleClose() {
            this.drawerAddClue = false;
            this.onClose();
        },
        onClose() {
            sessionStorage.removeItem('customerlist');
            this.$router.go(-1);
            this.$choiceLabel.commit('emptyData');
        },
    },
};
</script>

<style lang="scss" scoped>
.add_cutomer_box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .title_box {
        height: 60px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;
        span {
            font-size: 16px;
            > i {
                font-style: normal;
                font-size: 20px;
                color: #2370eb;
            }
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            height: auto;
            display: block;
            i {
                font-size: 16px;
            }
        }
    }
}
.add_customer {
    background-color: #f0f2f4;
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
}
.right_form {
    margin-left: 10px;
    flex: 2;
}
.left_form {
    flex: 1;
}
.add_customer > div {
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
}
// .add_customer > div p {
//     font-size: 16px;
// }
.wait_data {
    height: 50px;
    line-height: 50px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    position: relative;
}
.wait_data:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 16px;
    background-color: #2370eb;
}
.customer_form {
    padding-left: 30px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}
.customer_form:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 16px;
    background-color: #2370eb;
}
.customer_form span {
    border-radius: 1px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
}
.customer_form div {
    float: right;
    font-size: 14px;
}
.customer_form div span {
    color: #2370eb;
    position: relative;
    cursor: pointer;
}
.customer_form div span:first-child {
    margin-right: 50px;
}
.right_form_main {
    display: flex;
    height: calc(100% - 51px);
    overflow: hidden;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
}
.customer_form .active:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #2370eb;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
}
.add_customer .business_info {
    margin-top: 25px;
    position: relative;
    margin-bottom: 10px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    padding-left: 35px;
}
.add_customer .business_info:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 2px;
    background: #2370eb;
}
.add_customer .business_info .hide_btn {
    float: right;
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    /* display: inline-block; */
    color: #2370eb;
    cursor: pointer;
}
.add_cutomer_box .footer_btn {
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
}
.footer_btn button:nth-child(2) {
    margin: 0 10px 0 20px;
}
</style>
