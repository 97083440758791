<template>
    <div class="customerFrom">
        <el-form label-width="90px" label-position="left" size="small">
            <p class="contacts_form_title">基础信息</p>
            <el-form-item label="公司名称" required style="position: relative">
                <el-input
                    v-model="customerDO.companyName"
                    placeholder="请输入"
                    @input="inputCompanyName"
                ></el-input>
                <p
                    style="color: #666666; font-size: 12px"
                    v-if="
                        !tag &&
                        customerDO.companyName &&
                        customerDO.enterpriseType == 1
                    "
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    本公司名称重复，请修改
                </p>
                <div
                    class="domain_rules_box"
                    v-if="
                        customerDO.companyName && customerDO.enterpriseType == 1
                    "
                >
                    <el-button
                        v-if="tag"
                        type="primary"
                        class="el-icon-success"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-else
                        class="el-icon-warning"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <div
                class="companyList"
                v-if="
                    !tag &&
                    customerDO.companyName &&
                    customerDO.enterpriseType == 1
                "
            >
                <el-table
                    :data="companyList"
                    style="width: 100%"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column width="30">
                        <template slot-scope="scope">
                            <el-radio
                                v-model="tableRadio"
                                @change="changeTableRadio"
                                :label="scope.row"
                                ><i></i
                            ></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="客户名称">
                    </el-table-column>
                </el-table>
                <!-- <el-button size="mini" @click="onSync()">关联客户</el-button> -->
            </div>
            <template v-for="(item, index) in customerDomainList">
                <div
                    v-if="
                        !tag &&
                        customerDO.companyName &&
                        customerDO.enterpriseType == 1
                    "
                >
                    <el-form-item label="关联域名">
                        <el-input
                            style="width: 220px"
                            v-model="item.domain"
                            placeholder="关联域名"
                            size="small"
                            @input="
                                (val) => {
                                    inputDomain(val, index);
                                }
                            "
                        ></el-input>
                        <el-button
                            v-if="index == 0"
                            @click="addDomain"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click.prevent="removeDomain(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </el-form-item>
                    <p
                        v-show="item.tag"
                        style="font-size: 13px; padding-left: 100px"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="color: #f5a623"
                        ></i
                        >当前域名已存在，无需重复添加
                    </p>
                </div>
            </template>
            <div
                class="contactWay_show"
                v-if="
                    !tag &&
                    customerDO.companyName &&
                    customerDO.enterpriseType == 1
                "
            >
                联系方式
            </div>
            <template
                v-for="(item, index) in customerDO.customerMainContactList"
            >
                <div
                    style="margin-bottom: 8px"
                    v-if="
                        !tag &&
                        customerDO.companyName &&
                        customerDO.enterpriseType == 1
                    "
                >
                    <el-select
                        v-model="item.contactWay"
                        size="small"
                        style="width: 80px"
                        clearable
                        placeholder="方式"
                    >
                        <el-option label="电话" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="邮箱" :value="3"></el-option>
                        <el-option label="QQ" :value="4"></el-option>
                        <el-option label="短信" :value="5"></el-option>
                        <el-option label="拜访" :value="6"></el-option>
                        <!-- <el-option label="官网表单" :value="8"></el-option> -->
                        <el-option label="座机" :value="9"></el-option>
                        <el-option label="其他" :value="7"></el-option>
                    </el-select>
                    <el-input
                        style="margin-left: 5px; width: 148px"
                        v-model="item.contactContent"
                        placeholder="请输入"
                        size="small"
                        @input="
                            (val) => {
                                inputContactContent(item, index);
                            }
                        "
                    ></el-input>
                    <el-input
                        style="margin-left: 5px; width: 80px"
                        v-model="item.contactName"
                        placeholder="联系人"
                        size="small"
                    ></el-input>
                    <el-button
                        v-if="index == 0"
                        @click="addContact"
                        type="primary"
                        class="el-icon-plus"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click.prevent="removeContact(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                    <p
                        v-show="item.tag"
                        style="font-size: 13px; margin-top: 8px"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="color: #f5a623"
                        ></i
                        >当前联系方式已存在，无需重复添加
                    </p>
                </div>
            </template>
            <div
                v-if="
                    !(
                        !tag &&
                        customerDO.companyName &&
                        customerDO.enterpriseType == 1
                    )
                "
            >
                <el-form-item label="客户类型" required>
                    <el-radio-group v-model="customerDO.enterpriseType">
                        <el-radio :label="1">企业</el-radio>
                        <el-radio :label="2">个人</el-radio>
                        <el-radio :label="3">外资</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="域名">
                    <el-input
                        v-model="customerDO.companyDomain"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
                <template v-for="(item, index) in customerDomainList">
                    <div>
                        <el-form-item label="关联域名">
                            <el-input
                                style="width: 220px"
                                v-model="item.domain"
                                placeholder="关联域名"
                                size="small"
                            ></el-input>
                            <el-button
                                v-if="index == 0"
                                @click="addDomain"
                                type="primary"
                                class="el-icon-plus"
                                circle
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0"
                                @click.prevent="removeDomain(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                size="mini"
                            ></el-button>
                        </el-form-item>
                    </div>
                </template>

                <el-form-item label="联系方式">
                    <div
                        class="info contact_way_box"
                        v-for="(
                            item, index
                        ) in customerDO.customerMainContactList"
                    >
                        <el-button
                            v-if="index > 0"
                            type="primary"
                            @click="toTop(index)"
                            class="el-icon-top ordinary"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            type="primary"
                            @click="toTop(index)"
                            class="el-icon-top ordinary"
                            circle
                            size="mini"
                        ></el-button>
                        <el-select
                            v-model="item.contactWay"
                            size="small"
                            style="width: 60px"
                            clearable
                            placeholder="方式"
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>
                            <!-- <el-option label="官网表单" :value="8"></el-option> -->
                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                        <el-input
                            style="margin-left: 5px; width: 90px"
                            v-model="item.contactContent"
                            placeholder="请输入"
                            size="small"
                        ></el-input>
                        <el-input
                            style="margin-left: 5px; width: 60px"
                            v-model="item.contactName"
                            placeholder="联系人"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="index == 0"
                            @click="addContact"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click.prevent="removeContact(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </div>
                </el-form-item>
                <div>
                    <!-- <el-form-item label="原邮箱品牌">
                        <el-select
                            v-model="customerDO.primaryEmailBrand"
                            size="small"
                            style="width: 220px"
                            clearable
                            placeholder="原邮箱品牌"
                        >
                            <el-option
                                v-for="item in $searchForm.mailboxbrand()"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="到期时间">
                        <el-date-picker
                            v-model="customerDO.customerExpireTime"
                            type="date"
                            value-format="yyyy-MM-dd hh:mm:ss"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item label="客户标签">
                        <span
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            class="labelList"
                            v-for="(item, index) in $choiceLabel.state
                                .labelList"
                            >{{ item.tagName }}
                            <span class="cancel" @click="onDeleteLabel(item)">
                                <span class="el-icon-close"></span>
                            </span>
                        </span>
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            @click="onChoiceLabel"
                            style="
                                background: rgba(0, 0, 0, 0.02);
                                border-color: rgba(0, 0, 0, 0.15);
                                color: #000000;
                                padding: 9px;
                            "
                        ></el-button>
                    </el-form-item>
                    <el-form-item
                        label="所在地"
                        v-if="customerDO.enterpriseType != 1"
                    >
                        <el-cascader
                            placeholder="所在地"
                            style="width: 220px"
                            v-if="customerDO.enterpriseType == 2"
                            clearable
                            v-model="address"
                            :options="$address.cascader()"
                        ></el-cascader>
                        <el-input
                            v-model="location"
                            placeholder="请输入"
                            v-if="customerDO.enterpriseType == 3"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="邮箱版本"
                        v-if="
                            customerDO.primaryEmailBrand == 9 ||
                            customerDO.primaryEmailBrand == 10 ||
                            customerDO.primaryEmailBrand == 8 ||
                            customerDO.primaryEmailBrand == 35
                        "
                    >
                        <el-select
                            v-model="customerDO.emailVersionType"
                            size="small"
                            style="width: 220px"
                            clearable
                            placeholder="邮箱版本"
                        >
                            <el-option
                                label="全新用户"
                                v-if="
                                    customerDO.primaryEmailBrand == 9 ||
                                    customerDO.primaryEmailBrand == 10
                                "
                                :value="1"
                            ></el-option>
                            <el-option
                                label="已有新版基础版"
                                v-if="customerDO.primaryEmailBrand <= 1"
                                :value="2"
                            ></el-option>
                            <el-option
                                label="已有旧版基础版"
                                v-if="customerDO.primaryEmailBrand <= 1"
                                :value="3"
                            ></el-option>
                            <el-option
                                label="阿里-标准版"
                                v-if="
                                    customerDO.primaryEmailBrand == 8 ||
                                    customerDO.primaryEmailBrand == 35
                                "
                                :value="4"
                            ></el-option>
                            <el-option
                                label="阿里-尊享版"
                                v-if="
                                    customerDO.primaryEmailBrand == 8 ||
                                    customerDO.primaryEmailBrand == 35
                                "
                                :value="5"
                            ></el-option>
                            <el-option
                                label="阿里-集团版"
                                v-if="
                                    customerDO.primaryEmailBrand == 8 ||
                                    customerDO.primaryEmailBrand == 35
                                "
                                :value="6"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-if="customerDO.primaryEmailBrand == 1"
                        :label="
                            customerDO.emailVersionType == 1
                                ? '手机号'
                                : customerDO.emailVersionType == 2
                                ? '企业ID'
                                : '管理员账号'
                        "
                    >
                        <el-input
                            v-model="customerDO.emailVersionTypeName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="所属行业"
                        v-if="customerDO.enterpriseType != 1"
                    >
                        <el-select
                            v-model="customerDO.business"
                            size="small"
                            style="width: 220px"
                            clearable
                            placeholder="所属行业"
                        >
                            <el-option
                                v-for="item in $industry.industry()"
                                :label="item.label"
                                :value="item.value"
                                :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计用户数">
                        <el-input
                            v-model.number="customerDO.number"
                            placeholder="请输入"
                            @input="
                                customerDO.number = customerDO.number.replace(
                                    /[^0-9]/g,
                                    ''
                                )
                            "
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="预计年限">
                        <el-input
                            v-model.number="customerDO.term"
                            placeholder="请输入"
                            @input="
                                customerDO.term = customerDO.term.replace(
                                    /[^0-9]/g,
                                    ''
                                )
                            "
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="地址"
                        v-if="customerDO.enterpriseType != 1"
                    >
                        <el-input
                            v-model="customerDO.address"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="联系备注">
                        <el-input
                            v-model="customerDO.contactRemark"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            type="textarea"
                            style="width: 220px"
                            rows="6"
                            placeholder="请输入"
                            v-model="customerDO.remark"
                        ></el-input>
                    </el-form-item> -->
                </div>
            </div>
        </el-form>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
                :labelType="labelType"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>

<script>
import { relationCustomer } from '@/api/database/database';
import ChoiceLabel from '../../../../components/choice-label/choiceLabel.vue';
import AddLable from '../../../../components/choice-label/addLabel.vue';
import {
    customerAddMy,
    getCustomerAdmin,
    getCustomerId,
} from '@/api/user/ae/customer.js';
import ContactsForm from './contactsForm.vue';
export default {
    name: '',
    props: [],
    data() {
        return {
            // 客户基本信息
            customerDO: {
                companyName: '',
                companyDomain: '',
                customerMainContactList: [],
                number: '',
                remark: '',
                enterpriseType: 1,
                primaryEmailBrand: '',
                contactRemark: '',
            },
            location: '',
            customerContactList: [],
            // 客户标签列表
            customerTagList: [],
            companyList: [],
            // 客户域名列表
            customerDomainList: [],
            address: '',
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            tag: false,
            labelType: '',
            tableRadio: {},
            formDataId: '',
        };
    },
    components: {
        ChoiceLabel,
        AddLable,
        ContactsForm,
    },

    mounted() {},
    methods: {
        inputCompanyName(val) {
            if (this.customerDO.enterpriseType == 1) {
                this.$emit('inputCompanyName', val);
            } else {
            }
        },
        async changeTableRadio(val) {
            let result = await this.getCustomer();
            console.log(result);
            this.customerDomainList.forEach((item) => {
                if (
                    item.domain == result.data.companyDomain &&
                    result.data.companyDomain != ''
                ) {
                    item.tag = true;
                }
                result.data.customerDomainList.forEach((itm) => {
                    if (val == itm.domain) {
                        item.tag = true;
                    }
                });
            });
            this.customerDO.customerMainContactList.forEach((item) => {
                result.data.customerMainContactList.forEach((itm) => {
                    if (
                        item.contactContent == itm.contactContent &&
                        item.contactName == itm.contactName &&
                        item.contactWay == itm.contactWay
                    ) {
                        item.tag = true;
                    }
                });
            });
            this.customerDO = JSON.parse(JSON.stringify(this.customerDO));
            this.customerDomainList = JSON.parse(
                JSON.stringify(this.customerDomainList)
            );
        },
        async inputContactContent(item, index) {
            console.log(item);
            let result = await this.getCustomer();
            if (
                result.data.customerMainContactList.some((itm) => {
                    return item.contactContent == itm.contactContent;
                })
            ) {
                this.customerDO.customerMainContactList[index].tag = true;
            } else {
                this.customerDO.customerMainContactList[index].tag = false;
            }
            this.customerDO = JSON.parse(JSON.stringify(this.customerDO));
        },
        async inputDomain(val, index) {
            let result = await this.getCustomer();
            if (
                val == result.data.companyDomain &&
                result.data.companyDomain != ''
            ) {
                this.customerDomainList[index].tag = true;
            }

            if (
                result.data.customerDomainList.some((item) => {
                    return val == item.domain;
                })
            ) {
                this.customerDomainList[index].tag = true;
            } else {
                this.customerDomainList[index].tag = false;
            }
            for (let i = 0; i < this.customerDomainList.length; i++) {
                if (i != index) {
                    if (this.customerDomainList[i].domain == val) {
                        this.customerDomainList[index].tag = true;
                    } else {
                        this.customerDomainList[index].tag = false;
                    }
                }
            }
            this.customerDomainList = JSON.parse(
                JSON.stringify(this.customerDomainList)
            );
        },
        getCustomer() {
            let obj = {
                param: {
                    id: this.tableRadio.id,
                },
            };
            return getCustomerId(obj);
        },
        // 同步
        onSync() {
            if (!this.tableRadio) {
                return this.$message.error('请选择客户');
            }
            let data = {
                param: {
                    customerDO: { id: this.tableRadio.id },
                    libraryDO: { id: this.$route.query.id },
                },
            };
            relationCustomer(data).then((res) => {
                if (res.code == 200) {
                    let data2 = {
                        param: {
                            companyName: this.tableRadio.companyName,
                            adminId: sessionStorage.getItem('adminId'),
                        },
                    };
                    getCustomerAdmin(data2).then((res) => {
                        if (res.code == 200) {
                            if (res.data.total > 0) {
                                this.$message.success('操作成功');
                                sessionStorage.removeItem('customerlist');
                                this.$router.go(-1);
                                this.$choiceLabel.commit('emptyData');
                            } else {
                                let datas = {
                                    param: {
                                        id: this.tableRadio.id,
                                    },
                                };
                                customerAddMy(datas).then((ress) => {
                                    if (ress.code == 200) {
                                        this.$message.success('操作成功');
                                        sessionStorage.removeItem(
                                            'customerlist'
                                        );
                                        this.$router.go(-1);
                                        this.$choiceLabel.commit('emptyData');
                                    }
                                });
                            }
                        }
                    });
                }
            });
        },
        // 添加域名
        addDomain() {
            this.customerDomainList.push({});
        },
        // 删除域名
        removeDomain(index) {
            this.customerDomainList.splice(index, 1);
        },
        // 添加联系方式
        addContact() {
            this.customerDO.customerMainContactList.push({
                contactWay: 9,
                contactContent: '',
            });
        },
        // 删除联系方式
        removeContact(index) {
            this.customerDO.customerMainContactList.splice(index, 1);
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 选择标签
        onChoiceLabel() {
            // console.log(this.$choiceLabel.state.labelList);
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '2');
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
        },
        handleClose() {
            this.dialogChoice = false;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        // 置顶
        toTop(index) {
            let arr = this.customerDO.customerMainContactList.splice(index, 1);
            // console.log(arr);
            this.customerDO.customerMainContactList.unshift(arr[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customerFrom {
    width: 50%;
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 20px;
    padding-right: 10px;
    border-right: 1px solid #e5e5e5;
    /deep/ .el-textarea__inner {
        resize: none;
    }
    .contacts_form_title {
        font-size: 14px;
        margin-bottom: 15px;
        color: #333;
        font-weight: 600;
        position: relative;
    }
    .contacts_form_title:before {
        content: '';
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #2370eb;
    }
    /deep/ .el-form-item {
        margin: 8px !important;
    }
}
.el-input {
    width: 220px;
}
.domain_rules_box {
    position: absolute;
    left: 223px;
    top: 2px;
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 16px;
        font-weight: 600;
    }
    .el-icon-success {
        color: #46a136 !important;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
.el-icon-circle-plus-outline,
.el-icon-remove-outline {
    color: #2370eb;
    font-size: 20px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 5px;
}
.customerFrom .contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}
.contact_way_box {
    /deep/ .el-input__inner {
        padding: 0 5px;
    }
}
.contactWay_show {
    font-size: 14px;
    font-weight: 600;
    padding-left: 8px;
    margin-bottom: 12px;
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
.companyList {
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    button {
        background: #2370eb;
        border-radius: 13px;
        color: #ffffff;
        margin: 12px;
        margin-left: 40px;
    }
}
.line {
    width: calc(100% + 30px);
    height: 1px;
    background-color: #e5e5e5;
    margin-left: -20px;
    margin: 10px 0 20px -20px;
}
</style>
