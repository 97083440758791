<template>
    <div class="businessInfo">
        <el-form
            label-width="80px"
            label-position="left"
            size="small"
            v-if="businessInfo"
        >
            <el-form-item label="法人">
                <div class="info">{{ businessInfo.legalPerson || '- -' }}</div>
            </el-form-item>
            <el-form-item label="负责人">
                <div class="info">{{ businessInfo.aiChaStatus || '- -' }}</div>
            </el-form-item>
            <!-- <el-form-item label="公司名称">
                <div class="info">{{ businessInfo.companyName || '--' }}</div>
            </el-form-item> -->
            <el-form-item label="公司地址">
                <div class="info">
                    {{ businessInfo.companyAddress || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="公司电话">
                <div class="info">{{ businessInfo.contactsStr || '- -' }}</div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['businessInfo', 'wash'],
    data() {
        return {};
    },
    components: {},
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.businessInfo {
    background-color: #fff;
    padding-left: 30px;
    padding-right: 10px;
}
.businessInfo .info {
    padding-left: 10px;
    width: 250px;

    box-sizing: border-box;
    height: 100%;
}
/deep/ .el-form-item__label {
    color: #666 !important;
    font-weight: normal !important;
}
/deep/ .el-form-item {
    margin: 8px !important;
}
</style>
